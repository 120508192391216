import { template as template_271befbd83674585ad549ffbb4247967 } from "@ember/template-compiler";
const FKControlMenuContainer = template_271befbd83674585ad549ffbb4247967(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
