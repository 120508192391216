import { template as template_541de3e51cdd42ecb37325e5ada309cc } from "@ember/template-compiler";
const WelcomeHeader = template_541de3e51cdd42ecb37325e5ada309cc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
